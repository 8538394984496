import {
  Button,
  Grid,
  Switch,
  Tooltip,
  Typography,
  FormControl,
  List,
  ListItem,
  ListItemText,
  IconButton,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import MiniDrawer from "../../Components/Drawer";
import { getApiData } from "../../Apis/apiHelper";
import BaseSetting from "../../Apis/setting";
import { toast } from "react-toastify";
import EditIcon from "@mui/icons-material/Edit";
import { useNavigate } from "react-router-dom";
import { DataGrid } from "@mui/x-data-grid";

export default function AllUser() {
  const [rows, setRows] = useState([]);
  const navigate = useNavigate();
  useEffect(() => {
    AllUsers();
  }, []);

  const handleEdit = (id) => {
    // Perform edit action here using the ID or unique identifier of the row
    console.log(`Editing row with ID: ${id}`);
    navigate(`/productdetails`, { state: { id } });
    // You can open a modal, navigate to a different page, or handle editing logic here
  };

  async function AllUsers() {
    // setLoginBtnLoading(true);
    try {
      const response = await getApiData(
        BaseSetting.endpoint.userdetails,
        "GET"
      );
      const data = response;
      const receivedRows = data?.docs?.map((item, index) => ({
        id: index + 1,
        fullName: item.name,
        emailAddress: item.email,
        mobileNumber: item.mobileNumber,
        residencyAddress: item.address,
        idd: item.id,
      }));
      // eslint-disable-next-line no-const-assign
      setRows(receivedRows);
    } catch (error) {
      console.log("ERROR=====>>>>>", error);
      toast(error.toString(), { type: "error" });
      //   setLoginBtnLoading(false);
    }
  }

  const columns = [
    { field: "id", headerName: "ID", width: 100 },
    { field: "fullName", headerName: "Full name", width: 200 },

    { field: "mobileNumber", headerName: "MobileNumber", width: 180 },

    { field: "emailAddress", headerName: "Email Address", width: 250 },
    {
      field: "residencyAddress",
      headerName: "Residency Address",

      width: 500,
    },
    {
      width: 50,
      renderCell: (params) => (
        <IconButton onClick={() => handleEdit(params.row.idd)}>
          {" "}
          {/* handleEdit function should handle edit action */}
          <EditIcon />
        </IconButton>
      ),
    },
  ];

  return (
    <Grid container>
      <MiniDrawer
        header="All User"
        children={
          <>
            <Grid container justifyContent={"space-between"}>
              <Button variant="contained" onClick={() => {}}>
                All User Data
              </Button>
            </Grid>
            {console.log("rows====>>> ", rows)}
            <Grid>
              <div style={{ height: 650, width: "100%", paddingTop: 20 }}>
                <DataGrid
                  rows={rows}
                  columns={columns}
                  initialState={{
                    pagination: {
                      paginationModel: { page: 0, pageSize: 10 },
                    },
                  }}
                  pageSizeOptions={[10, 15, 20]}
                  // checkboxSelection
                />
              </div>
            </Grid>
          </>
        }
      />
    </Grid>
  );
}
