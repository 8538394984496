import {
    Button,
    Grid,
    Switch,
    Tooltip,
    Typography,
    FormControl,
    List,
    ListItem,
    ListItemText,
    IconButton,
  } from "@mui/material";
  import React, { useEffect, useState } from "react";
  import MiniDrawer from "../../Components/Drawer";
  import { getApiData, getApiData2, getquizuser } from "../../Apis/apiHelper";
  import BaseSetting from "../../Apis/setting";
  import { toast } from "react-toastify";
  import EditIcon from "@mui/icons-material/Edit";
  import { useNavigate } from "react-router-dom";
  import { DataGrid } from "@mui/x-data-grid";

import { useDispatch, useSelector } from 'react-redux';
  
  export default function QuizUser() {
    const [rows, setRows] = useState([]);
    const dispatch = useDispatch();
    const accessToken = useSelector(state => state.accessToken);
    const navigate = useNavigate();
    useEffect(() => {
      QuizUserApi();
    }, []);
  
    const handleEdit = (id) => {
      // Perform edit action here using the ID or unique identifier of the row
      console.log(`Editing row with ID: ${id}`);
      navigate(`/productdetails`, { state: { id } });
      // You can open a modal, navigate to a different page, or handle editing logic here
    };
  
    async function QuizUserApi() {
      // setLoginBtnLoading(true);
      try {
        const authToken = accessToken; // Replace 'YOUR_AUTH_TOKEN' with the actual token
        const response = await getquizuser(
          BaseSetting.endpoint.getquizuser,
          "GET",
          {
            headers: {
              Authorization: authToken
            }
          }
        );
        const data = response?.responseData?.data;
        console.log("data====>>>>", response?.responseData?.data);
        const receivedRows = data?.map((item, index) => ({
          id: index + 1,
          name: item?.name,
          mobileNumber: item.mobileNumber,
          platform:item.platform,
          usertype:item.userType,
          quizcount:item.quizCount,
          Status :item.status,
        }));
        // eslint-disable-next-line no-const-assign
        setRows(receivedRows);
      } catch (error) {
        console.log("ERROR=====>>>>>", error);
        toast(error.toString(), { type: "error" });
        //   setLoginBtnLoading(false);
      }
    }
  
  
    const columns = [
      { field: "id", headerName: "ID", width: 100 },
      { field: "name", headerName: "Username", width: 180 },
      { field: "mobileNumber", headerName: "Mobile Number", width: 180 },
      { field: "usertype", headerName: "User type", width: 180 },
      { field: "platform", headerName: "Platform", width: 180 },
      { field: "quizcount", headerName: "Quiz Count", width: 180 },
      { field: "Status", headerName: "Status", width: 180 },
      {
        field: "view",
        headerName: "View",
        width: 180,
        renderCell: (params) => (
          <Button
            variant="contained"
            color="success"
            onClick={() => alert('Coming soon')} // Wrap the alert function in an arrow function
          >
            View
          </Button>
        ),
      },
    ];
    
  
    return (
      <Grid container>
        <MiniDrawer
          header="Quiz User"
          children={
            <>
              
              {console.log("rows====>>> ", rows)}
              <Grid>
               
                <div style={{ height: 650,width: "100%", paddingTop: 20 }}>
                  <DataGrid
                    rows={rows}
                    columns={columns}
                    autoHeight
                    autoWidth                  
                    initialState={{
                      pagination: {
                        paginationModel: { page: 0, pageSize: 10 },
                      },
                    }}
                    pageSizeOptions={[10, 15, 20]}
                    // checkboxSelection
                  />
                </div>
                
              </Grid>
            </>
          }
        />
      </Grid>
    );
  }
  