import React from "react";
import { Button } from "@mui/material";
import Lottie from "lottie-react";
import styles from "./styles";
import lottieFile from "../../Config/Files";
import BaseColor from "../../Config/Color";
import CIcon from "../CIcon";
import { IoClose } from "react-icons/io5";
import Files from "../../Config/Files";
import PropTypes from "prop-types";

const CButton = (props) => {
  const classes = styles();
  const {
    children,
    variant = "contained",
    color = "primary",
    loading = false,
    onClick,
    loaderStyle,
    removeImg,
    cameraIcon,
  } = props;

  return removeImg ? (
    <div className={classes.removeBtnContainer} onClick={onClick}>
      <div className={classes.removeBtn}>
        <IoClose size={20} color={BaseColor.red} />
      </div>
    </div>
  ) : cameraIcon ? (
    <div className={classes.cameraIconBtnContainer} onClick={onClick}>
      <div className={classes.cameraIcon}>
        <CIcon src={Files.svgIcons.camera} size={18} />
      </div>
    </div>
  ) : (
    <div className={classes.root}>
      <Button
        variant={variant}
        color={color}
        disabled={loading}
        onClick={onClick}
        className={classes.button}
        {...props}
      >
        {loading ? (
          <div
            style={{
              ...loaderStyle,
              position: "absolute",
              height: 40,
              width: 200,
              top: "-90%",
            }}
          >
            <Lottie
              style={{ zIndex: 1 }}
              animationData={lottieFile.lottie.loader}
              loop={true}
              height={40}
              width="100%"
            />
          </div>
        ) : (
          children
        )}
      </Button>
    </div>
  );
};

CButton.propTypes = {
  color: PropTypes.string,
  loading: PropTypes.bool,
};

export default CButton;
