import { Suspense, useEffect } from "react";
import "./App.css";
import { Route, Routes, } from "react-router-dom";
import Home from "./App/Pages/Home";
import Login from "./App/Pages/Login";
import AllUser from "./App/Pages/AllUser";
import  User from "./App/Pages/User";
import FineClubUser from "./App/Pages/FineClubUser";
import CareerUser from "./App/Pages/CareerUser";  
import AddRagister from './App/Pages/Home';
import NewLetter from "./App/Pages/NewsLetter"
import QuizUser from "./App/Pages/QuizUsers";
import News from "./App/Pages/News";
import { useSelector } from "react-redux";
import _ from "lodash";
import Webinar from "./App/Pages/webinar"
import PlatFormUser from "./App/Pages/PlatformUser";
import { store } from "./App/Redux/Store/configureStore";
import Dashboard from "./App/Pages/DashBoard";
import BrokerUser from "./App/Pages/BrokerUser";

function App() {
  const { accessToken } = useSelector((state) => state.auth);

  // const navigate = useNavigate();

  useEffect(() => {
    const authState = store?.getState() || {};
    console.log("authState", authState)
    const token = authState?.auth?.accessToken || "";
    const webUrl = window.location.href;
    const webUrlArray = webUrl.split("/");
    const length = !_.isEmpty(webUrlArray) ? webUrlArray?.length - 1 : null;
    const pageName = webUrlArray[length];

    if (!_.isEmpty(token) && pageName === "") {
      window.location.replace("/webinar");
    } else if (_.isEmpty(token) && pageName !== "") {
      window.location.replace("/");
    }

    // if (token) {
    //   const decodedToken = jwt_decode(token);
    //   const tokenExpirationTime = decodedToken.exp * 1000; // Convert expiration time to milliseconds
    //   const currentTime = Date.now();
    //   const timeUntilExpiration = tokenExpirationTime - currentTime;

    //   setTimeout(() => {
    //     localStorage.clear();
    //     navigate("/login"); // redirect to login page
    //     toast("Session timeout", { type: "info" });
    //   }, timeUntilExpiration);
    // }
  }, []);

  console.log("accessToken", accessToken);

  return (
    <Suspense>
      <Routes>
        {!_.isEmpty(accessToken) ? (
          <>
            <Route exact path={"/"} element={<Login />} />
            {/* <Route path={"/dashboard"} element={<Dashboard />} /> */}
            <Route path={"/all_users"} element={<User />} />
            <Route path={"/finclub_users"} element={<FineClubUser />} />
            <Route path={"/courses_users"} element={<CareerUser />} />
            <Route path={"/quiz_user"} element={<QuizUser/>} />
            <Route path={"/broker_user"} element={<BrokerUser/>} />
            <Route path={"/newletter"} element={<NewLetter/>} />
            <Route path={"/news"} element={<News/>} />
            <Route path={"/alluser"} element={<AllUser/>} />
            <Route path={"/webinar"} element={<Webinar/>} />
            <Route path={"/platFormUser"} element={<PlatFormUser/>} />
       
          </>
         ) : (
          <>
          <Route exact path={"/"} element={<Login />} />
            <Route path={"/login"} element={<Login />} />
            <Route exact path={"/home"} element={<Home />} />
         
          
          </>
         )} 
      </Routes>
    </Suspense>
  );
}

export default App;
