import React, { useEffect, useState } from "react";
import { Button, Grid } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { getApiData2 } from "../../Apis/apiHelper";
import BaseSetting from "../../Apis/setting";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { saveAs } from 'file-saver';
import * as XLSX from 'xlsx';
import MiniDrawer from "../../Components/Drawer";
import BaseColor from "../../Config/Color";

export default function CareerUser() {
  const [rows, setRows] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    getCareerUsers();
  }, []);

  async function getCareerUsers() {
    try {
      const response = await getApiData2(
        BaseSetting.endpoint.fetchuser,
        "GET"
      );
      const data = response;
      const receivedRows = data?.result?.map((item, index) => ({
        id: index + 1,
        fullName: item.username,
        emailAddress: item.email,
        mobileNumber: item.mobileNo,
      }));
      setRows(receivedRows);
    } catch (error) {
      console.log("ERROR=====>>>>>", error);
      toast(error.toString(), { type: "error" });
    }
  }

  const columns = [
    { field: "id", headerName: "ID", width: 100 },
    { field: "fullName", headerName: "Name", width: 200 },
    { field: "emailAddress", headerName: "Email Address", width: 250 },
    { field: "mobileNumber", headerName: "Mobile Number", width: 180 },
  ];

  const downloadCsv = () => {
    const csvData = rows.map(row => Object.values(row).join(',')).join('\n');
    const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8' });
    saveAs(blob, 'userData.csv');
  };

  const downloadExcel = () => {
    const ws = XLSX.utils.json_to_sheet(rows);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "userData");
    const wbout = XLSX.write(wb, { bookType: 'xlsx', type: 'binary' });
    const blob = new Blob([s2ab(wbout)], { type: 'application/octet-stream' });
    saveAs(blob, 'userData.xlsx');
  };

  const s2ab = (s) => {
    const buf = new ArrayBuffer(s?.length);
    const view = new Uint8Array(buf);
    for (let i = 0; i < s?.length; i++) view[i] = s.charCodeAt(i) & 0xFF;
    return buf;
  };

  return (
    <Grid container>
      <MiniDrawer
        header="Career User"
        children={
          <>
            <div style={{padding: '0px 0px 20px 0px', justifyContent: 'flex-end', display: 'flex' }}>
              <Button variant="contained" onClick={downloadCsv}  style={{ background: BaseColor.blue }} >
                Download CSV
              </Button>
              <Button variant="contained" onClick={downloadExcel} style={{ marginLeft: 10, background: BaseColor.green }}>
                Download Excel
              </Button>
            </div>
            <div style={{ height: 650, width: "100%" }}>
              <DataGrid
                rows={rows}
                columns={columns}
                pageSize={10}
                checkboxSelection={false}
                disableSelectionOnClick />
            </div>
          </>
        }
      />
    </Grid>
  );
}
