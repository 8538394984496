// src/components/Dashboard.js
import{ React ,useState}from 'react';
import { makeStyles } from '@mui/styles';
import { CssBaseline,Grid, Drawer, AppBar, Toolbar, List, ListItem, ListItemIcon, ListItemText, Typography, Container ,Paper, Pagination} from '@mui/material';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import LocalMallIcon from '@mui/icons-material/LocalMall';
import AppRegistrationIcon from '@mui/icons-material/AppRegistration';
import AddIcon from '@mui/icons-material/Add';
import { useNavigate } from "react-router-dom";
import CInput from '../../Components/CInput';
import CButton from '../../Components/CButton';
import CModal from '../../Components/CModal';
import Button from '@mui/material/Button';
import MiniDrawer from '../../Components/Drawer';
import { useSelector } from 'react-redux';
import Divider from '@mui/material/Divider';



const drawerWidth = 210;

const useStyles = makeStyles((theme) => ({
  root: {
    // display: 'flex',
    // backgroundColor: 'black',

  },
  appBar: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  toolbar: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing(3),
  },
  box: {
    padding: theme.spacing(2),
    backgroundColor: '#f0f0f0', // Change this color for each box
    marginBottom: theme.spacing(2),
    height:140 ,
    width:240,
    position: 'relative',
  },
  boxes:{
    flex:1,
    flexDirection:'row',
    justifyContent:'space-around',
  },
  boxstyle : {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 500,
    bgcolor: 'black',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  },
}));

const Dashboard = () => {

  const classes = useStyles();
  const navigate = useNavigate();
  const token = useSelector((state) => state.auth.accessToken);

  console.log("token----->",token);
  const [open, setOpen] = useState(false);
  const[fname ,setFname] = useState('');
  const[lname ,setLname] = useState('');
  const[anumber ,setAnumber] = useState('');
  const[email ,setEmail] = useState('');
  const[address ,setAddress] = useState('');
  const [showList, setShowList] = useState([]);

  const itemsArray = ['Item 1', 'Item 2', 'Item 3', 'Item 4'];

  const handleOpen = () =>{
    setOpen(true);
  };

  const listshow = () =>{
    setShowList(itemsArray);
  }
  const handleClose = () => {
    setOpen(false);
    setShowList(false);
  }

  return (
    <div className={classes.root} style={{ flex: 1, }}>
    <Grid container>
      <MiniDrawer
        header="DashBoard"
        children={
          <>
           <Grid>
          <div
              style={{
                marginTop: "20px",
                display: "flex",
                justifyContent: "flex-start",
              }}
            >
              {/* <Button
                variant="contained"
                onClick={() => {
                  // getParentCategory();
                  // setIsEditModal(false);
                  // setCreateCategoryModal(true);
                }}
              > */}
                Welcome to Your DashBoard
              {/* </Button> */}
            </div>
            <div
            >
              <Grid container spacing={5} sx={{marginTop:5}}>
            <Grid item  sm={3}>
              <Paper elevation={3} className={classes.box}  sx={{
        '&:hover': {
          backgroundColor: 'lightgray', // Change background color on hover
          cursor: 'pointer', // Change cursor on hover
        },
      }}>
                 <div style={{display:'flex' ,flexDirection:'row' ,justifyContent:'flex-end'}}>
                <Typography variant="h5">User</Typography>
                </div>
                <div style={{height:80, width:80 , background:'pink',position:"absolute" ,top:-30,display:'flex', justifyContent:'center',alignItems:'center',borderRadius:5}}>
                <AccountCircleIcon  sx={{fontSize:35}}/>
                </div>
           
              </Paper>
            </Grid>
            <Grid item sm={6}>
              <Paper elevation={3} className={classes.box} >
                {/* Box 2 Content */}
            
             <div>
                <div style={{height:80, width:80 , background:'pink',position:"absolute" ,top:-30,display:'flex', justifyContent:'center',alignItems:'center',borderRadius:5}}>
            
                <LocalMallIcon   sx={{fontSize:35}}/>
              
                </div>
                <div style={{display:'flex' ,flexDirection:'row' ,justifyContent:'flex-end'}}>
                <Typography variant="h6">Product</Typography>
                </div>
             </div>
             <Divider />
             
              </Paper>
            </Grid>
            
            </Grid>
            </div>
            </Grid>
          </>
        }
      />
 
    </Grid>
    </div>
  );
};

export default Dashboard;