import {
    Button,
    Grid,
    Switch,
    Tooltip,
    Typography,
    FormControl,
    List,
    ListItem,
    ListItemText,
    IconButton,
  } from "@mui/material";
  import React, { useEffect, useState } from "react";
  import MiniDrawer from "../../Components/Drawer";
  import { getApiData, getApiData2, getbrokeruser, getquizuser } from "../../Apis/apiHelper";
  import BaseSetting from "../../Apis/setting";
  import { toast } from "react-toastify";
  import EditIcon from "@mui/icons-material/Edit";
  import { useNavigate } from "react-router-dom";
  import { DataGrid } from "@mui/x-data-grid";

import { useDispatch, useSelector } from 'react-redux';
  
  export default function BrokerUser() {
    const [rows, setRows] = useState([]);
  
    const  accessToken  = useSelector((state) => state.auth);
    console.log("accessToken==>" ,accessToken.accessToken)
    const navigate = useNavigate();
    useEffect(() => {
      AllUsers();
    }, []);
  
    const handleEdit = (id) => {
      // Perform edit action here using the ID or unique identifier of the row
      console.log(`Editing row with ID: ${id}`);
      navigate(`/productdetails`, { state: { id } });
      // You can open a modal, navigate to a different page, or handle editing logic here
    };
  
    async function AllUsers() {
        try {
          const authToken = accessToken.accessToken;
          console.log("authToken1234===>", authToken);
      
          // Make the GET request with headers
          const response = await fetch('https://dev.finnovationz.com:3250/api/user', {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbWFpbCI6ImhlZXJwYXRlbDIyMzBAZ21haWwuY29tIiwiaWF0IjoxNzE0NTU5NzE4LCJleHAiOjE3MTQ2NDYxMTh9.uSiIgTe2WUpf45Hinp0PUUnJMNyC8enlrAY_cDN6O2s`
            }
          });
      
          // Check if the response is unauthorized
          if (response.status === 401) {
            console.log("Unauthorized: Invalid token");
            return;
          }
      
          // Parse response JSON
          const data = response;
          console.log("data====>>>>", data);
          const receivedRows = data?.data.map((item, index) => ({
            id: index + 1,
            name: item.name,
            emailAddress: item.email,
            mobileNumber: item.phoneNumber,
            experience:item.experience,
            explore:item.exploreFutureAndOptions,
            createdat:item.createdAt,
            usertype:item.userType,
          }));
          // eslint-disable-next-line no-const-assign
          setRows(receivedRows);
      
      
        } catch (error) {
          console.log("ERROR=====>>>>>", error);
          toast(error.toString(), { type: "error" });
          // Handle errors
        }
      }
      
      
      
      
  
    const columns = [
      { field: "id", headerName: "ID", width: 100 },
   
      { field: "name", headerName: "Name", width: 180 },
   
      { field: "usertype", headerName: "User type", width: 180 },
      { field: "emailAddress", headerName: "Email Address", width: 250 },
      { field: "mobileNumber", headerName: "MobileNumber", width: 180 },
      
      { field: "experience", headerName: "Experience", width: 180 },
      
      { field: "explore", headerName: "Explore Future and Options", width: 180 },
      { field: "createdat", headerName: "Created At", width: 180 },
     
    
    //   {
    //     width: 50,
    //     renderCell: (params) => (
    //       <IconButton onClick={() => handleEdit(params.row.idd)}>
    //         {" "}
    //         {/* handleEdit function should handle edit action */}
    //         <EditIcon />
    //       </IconButton>
    //     ),
    //   },
    ];
  
    return (
      <Grid container>
        <MiniDrawer
          header="Broker User"
          children={
            <>
              
              {console.log("rows====>>> ", rows)}
              <Grid>
               
                <div style={{ height: 650,width: "100%", paddingTop: 20 }}>
                  <DataGrid
                    rows={rows}
                    columns={columns}
                    initialState={{
                      pagination: {
                        paginationModel: { page: 0, pageSize: 10 },
                      },
                    }}
                    pageSizeOptions={[10, 15, 20]}
                    // checkboxSelection
                  />
                </div>
                
              </Grid>
            </>
          }
        />
      </Grid>
    );
  }
  