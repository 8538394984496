import {
    Button,
    Grid,
    Switch,
    Tooltip,
    Typography,
    FormControl,
    List,
    ListItem,
    ListItemText,
    IconButton,
    TextField,
    Autocomplete,
  } from "@mui/material";
  import React, { useEffect, useState } from "react";
  import MiniDrawer from "../../Components/Drawer";
  import {
    getApiData,
    getApiData2,
    getbrokeruser,
    getquizuser,
  } from "../../Apis/apiHelper";
  import BaseSetting from "../../Apis/setting";
  import { toast } from "react-toastify";
  import EditIcon from "@mui/icons-material/Edit";
  import { useNavigate } from "react-router-dom";
  import { DataGrid } from "@mui/x-data-grid";
  import DeleteIcon from "@mui/icons-material/Delete";
  import { useDispatch, useSelector } from "react-redux";
  import Select from '@mui/material/Select';
  import MenuItem from '@mui/material/MenuItem';
  import { saveAs } from 'file-saver';
import * as XLSX from 'xlsx';
import BaseColor from "../../Config/Color";
  const moment = require("moment");
  
  
  
  
  
  export default function PlatFormUser() {
    const [rows, setRows] = useState([]);
    const [selectedWebinar, setSelectedWebinar] = useState('Select Platform');

    const handleWebinarSelection = async (event) => {
        const selectedValue = event.target.value;
        setSelectedWebinar(selectedValue);

        // Example API endpoint (replace with your actual endpoint)
        const apiUrl = selectedValue ===  "Select Platform" ?   `${process.env.REACT_APP_WEBINAR}/users/fetchUser` : `${process.env.REACT_APP_WEBINAR}/users/fetchUser?platform=${selectedValue}`;
    
        try {
          const response = await fetch(apiUrl);
          if (!response.ok) {
            throw new Error('Failed to fetch data');
          }
          const data = await response.json();
          console.log('API Response:', data); console.log("data====>>>>", data.result);
          const receivedRows = data.result?.map((item, index) => ({
            id: item._id,
            idindex : index+ 1,
            Email: item.email,
            MobileNo: item.mobileNo,
            Username: item.username,
            Platform: item.platform,
            Seminardate: convertDateFormat(item.seminardate),
            CreatedAt: convertDateFormat(item.created_at),
            UpdatedAt: convertDateFormat(item.updatedAt),
          }));
          // eslint-disable-next-line no-const-assign
          
          setRows(receivedRows);
          // Handle response data as needed (e.g., update state, show message)
        } catch (error) {
          console.error('Error fetching data:', error);
          // Handle error scenario (e.g., show error message)
        }
      };
    const accessToken = useSelector((state) => state.auth);
    console.log("accessToken==>", accessToken.accessToken);
    const navigate = useNavigate();
    useEffect(() => {
      Webinar();
    }, []);
  
  
  
  
  
    function convertDateFormat(dateString) {
      // Parse the date string using Moment.js
      const date = moment(dateString);
  
      // Format the date into a normal date format
      const normalDateFormat = date.format("YYYY-MM-DD");
  
      return normalDateFormat;
    }
  
 
    const downloadCsv = () => {
      const csvData = rows.map(row => Object.values(row).join(',')).join('\n');
      const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8' });
      saveAs(blob, 'userData.csv');
    };
 

    const seenPlatforms = new Set();
    async function Webinar() {
      try {
        const authToken = accessToken.accessToken;
        console.log("authToken1234===>", authToken);
  
        // Make the GET request with headers
        const response = await fetch(
          `${process.env.REACT_APP_WEBINAR}/users/fetchUser`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${authToken}`,
            },
          }
        );
  
        // Check if the response is unauthorized
        if (response.status === 401) {
          console.log("Unauthorized: Invalid token");
          return;
        }
  
        // Parse response JSON
        const data = await response.json();
        console.log("data====>>>>", data.result);
        const receivedRows = data.result?.map((item, index) => ({
          id: item._id,
          idindex : index+ 1,
          Email: item.email,
          MobileNo: item.mobileNo,
          Username: item.username,
          Platform: item.platform,
          Seminardate: convertDateFormat(item.seminardate),
          CreatedAt: convertDateFormat(item.created_at),
          UpdatedAt: convertDateFormat(item.updatedAt),
          comments : item.comment,



        }));
        // eslint-disable-next-line no-const-assign
        setRows(receivedRows);
      } catch (error) {
        console.log("ERROR=====>>>>>", error);
        toast(error.toString(), { type: "error" });
        // Handle errors
      }
    }
  
    const columns = [
      { field: "idindex", headerName: "No.", width: 100 },
      { field: "Email", headerName: "Email", width: 250 },
      { field: "MobileNo", headerName: "mobileNo", width: 200 },
      { field: "Username", headerName: "username", width: 200 },
      { field: "Platform", headerName: "platform", width: 200 },
      { field: "Seminardate", headerName: "seminardate", width: 200 },
      { field: "CreatedAt", headerName: "createdAt", width: 200 },
      { field: "UpdatedAt", headerName: "updatedAt", width: 200 },
      { field: "comments", headerName: "comments", width: 250 },
     
  
    //   {
    //     field: "Edit",
    //     headerName: "Edit",
    //     width: 100,
    //     renderCell: (params) => (
    //       <IconButton
    //       onClick={() => handleEdit(params.row.id)}
    //       >
    //         {" "}
    //         {/* handleEdit function should handle edit action */}
    //         <EditIcon />
    //       </IconButton>
    //     ),
    //   },
    //   {
    //     field: "delete",
    //     headerName: "Delete",
    //     width: 100,
    //     renderCell: (params) => (
    //       <Tooltip title="Delete">
    //         <IconButton
    //         onClick={() => handleDelete(params.row.id)}
    //         >
    //           <DeleteIcon />
    //         </IconButton>
    //       </Tooltip>
    //     ),
    //   },
    ];
    const downloadExcel = () => {
      const ws = XLSX.utils.json_to_sheet(rows);
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, "userData");
      const wbout = XLSX.write(wb, { bookType: 'xlsx', type: 'binary' });
      const blob = new Blob([s2ab(wbout)], { type: 'application/octet-stream' });
      saveAs(blob, 'userData.xlsx');
    };
  
    const s2ab = (s) => {
      const buf = new ArrayBuffer(s?.length);
      const view = new Uint8Array(buf);
      for (let i = 0; i < s?.length; i++) view[i] = s.charCodeAt(i) & 0xFF;
      return buf;
    };
    return (
      <Grid container>
        <MiniDrawer
          header="Platform User"
          children={
            <>
              {console.log("rows====>>> ", rows)}
              <div
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
              margin: 10,
            }}
          >
               <div style={{  justifyContent: 'flex-end' , display: 'flex'}}>
              <Button variant="contained" style={{background: BaseColor.blue}} onClick={downloadCsv}>
                Download CSV
              </Button>
              <Button variant="contained" onClick={downloadExcel} style={{ marginLeft: 10,background: BaseColor.green ,marginRight:'10px' }}>
                Download Excel
              </Button>
            </div>
            <Select
              value={selectedWebinar}
              onChange={handleWebinarSelection}
             placeholder="Select"
              style={{
                minWidth: '200px',
                // backgroundColor: '#4154f1',
                color: '#000',
                borderRadius: 10,
                paddingLeft: 10,
              }}
            >
              <MenuItem value="Select Platform">
                <em>Select Platform</em>
              </MenuItem>
              
               {rows.map((item, index) => {
        if (!seenPlatforms.has(item.Platform)) {
          seenPlatforms.add(item.Platform);
          return (
            <MenuItem key={index} value={item.Platform}>
              {item.Platform}
            </MenuItem>
          );
        }
        return null;
      })}  
        

              
            </Select>    
          </div>
              <Grid>
                <div style={{ height: 650, width: "100%", paddingTop: 20 }}>
                {rows.length > 0 ? (
                <DataGrid
                  rows={rows}
                  columns={columns}
                  initialState={{
                    pagination: {
                      paginationModel: { page: 0, pageSize: 10 },
                    },
                  }}
                  pageSizeOptions={[10, 15, 20]}
                  disableSelectionOnClick
                />
              ) : (
                <p>No webinars available.</p>
              )}
                </div>
              </Grid>
            </>
          }
        />
      
      </Grid>
    );
  }
  