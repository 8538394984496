import types from "./actions";

const initialState = {
  accessToken: "",
  userData: {},
};

export default function reducer(state = initialState, actions) {
  switch (actions.type) {
    case types.SET_ACCESS_TOKEN:
      console.log("actions.accessToken", actions);
      return {
        accessToken: actions.accessToken,
      };
    case types.SET_USER_DATA:
      return { ...state, userData: actions.userData };
    default:
      return state;
  }
}
