import moment from 'moment'

const isLive = true
const liveApiUrl = 'https://dev.convey.in:5002/api/admin-auth'
const localApiUrl = 'https://dev.convey.in:5002/api/admin-auth'
const userApiurl= "https://money.finnovationz.com:3008/users"
const fineClubApiurl= "https://finclub.finnovationz.com:3007/users"
const quizapiurl ="https://dev.finnovationz.com:3251/api/users"
const newsletter ="https://dev.blog.finnovationz.com:3331/api/subscribes"
const finovation = process.env.REACT_APP_WEBINAR
const BaseSetting = {
  siteName: 'finnovation Admin',
  siteIcon: '',
  footerText: `© ${moment().year()} Finnovation Inc. All rights reserved.`,
  GOOGLE_CLIENT_ID: '',
  // ------------------------------Client Domain----------------------------------------
  // -----------------------------------------------------------------------------------
  //   domainUrl: isLive ? liveApiUrl : localApiUrl,
  apiUrl: isLive ? liveApiUrl : localApiUrl,
  userApiurl: userApiurl,
  fineClubApiurl: fineClubApiurl,
  quizapiurl :quizapiurl,
  newsletter: newsletter,
  finovation :finovation,
  revalidate: true,
  templateKey: '',
  mapKey: '',
  endpoint: {
     fetchuser :'/fetchuser',
    adminlogin: '/request-otp',
    verifyotp: "/verify-otp",
    resendotp :"/resend-otp",
    getdevicedetials :"/v1/users/get-device-details",
    getquizuser :"/admin/user-list",
    getnewsletter :'/admin',
    login:'/admin-login',
    webinar :'/webinar/webinarDetail'
  }
}

export default BaseSetting
