import {
  Button,
  Grid,
  Switch,
  Tooltip,
  Typography,
  FormControl,
  List,
  ListItem,
  ListItemText,
  IconButton,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import MiniDrawer from "../../Components/Drawer";
import { getApiData } from "../../Apis/apiHelper";
import BaseSetting from "../../Apis/setting";
import { toast } from "react-toastify";
import CModal from "../../Components/CModal";
import { FontFamily } from "../../Config/theme";
import { Edit } from "@mui/icons-material";
import BaseColor from "../../Config/Color";
import _, { isEmpty } from "lodash";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import CInput from "../../Components/CInput";
import CButton from "../../Components/CButton";
import jsPDF from "jspdf";
import { useNavigate } from "react-router-dom";
import AddIcon from "@mui/icons-material/Add";
import Papa from "papaparse";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";
import { useSelector } from "react-redux";
// import { DataGrid } from '@material-ui/data-grid';

export default function Home() {
  const [categoryData, setCategoryData] = useState([]);
  // const token = useSelector((state) => state.auth.accessToken);
  // console.log('token home ===>> ', token);

  const [parentCategoryData, setParentCategoryData] = useState([]);
  const [setIsEditModal] = useState(false);
  const [editId, setEditId] = useState(-1);
  const [setCreateCategoryModal] = useState(false);
  const [name, setName] = useState("");
  const [parentId, setParentId] = useState(null);
  const [setDisableBtn] = useState(false);
  const [setCreateBtnLoading] = useState(false);
  const [setNameErrorText] = useState("");
  const [pagination, setPagination] = useState({});
  const [setPageLoad] = useState(false);
  const [pageNo] = useState(1);
  const [open, setOpen] = useState(false);
  const [open1, setOpen1] = useState(false);
  const [open2, setOpen2] = useState(false);
  const [fname, setFname] = useState("");
  const [lname, setLname] = useState("");
  const [email, setEmail] = useState("");
  const [address, setAddress] = useState("");
  const [device, setDevice] = useState("");
  const [selectObject, setSelectObject] = useState({});
  const [data, setData] = useState([]);
  const [newItem, setNewItem] = useState("");
  const [newItem2, setNewItem2] = useState("");
  const [indexSelect, setindexSelect] = useState(-1);
  const [iSelect, setiSelect] = useState(-1);

  console.log("------", data);
  const handleOpen = () => {
    setOpen(true);
  };

  const handleInputChange1 = (e) => {
    setNewItem(e.target.value);
    setNewItem([...data, e.target.value]);
  };
  const handleClose = (e) => {
    addItem();
    setNewItem("");
  };
  const handleClose1 = (e) => {
    setOpen1(false);
    setOpen2(false);
  };

  useEffect(() => {
    console.log("data======== hello ===>>>", data);
    setData(data);
  }, [data]);
  const navigate = useNavigate();

  const [visibleColumns, setVisibleColumns] = useState([
    "id",
    "name",
    "category",
  ]);

  const addItem = () => {
    const newItemObj = { id: data?.length + 1, name: device, switchdetails: [] };
    setData([...data, newItemObj]);
    console.log("Hello==>", data);
    setOpen(false);
    setNewItem(data);
  };

  const deleteswitch = (index, i) => {
    let dumyarray = [...data];
    dumyarray[index].switchdetails.splice(i, 1);
    setData(dumyarray);
  };
  const editdetails = (index, i) => {
    console.log("selected object====>", selectObject);
    console.log("newItem2 before update: ", newItem2);
    setOpen2(true);
    setindexSelect(index);
    setiSelect(i);
  };

  const editSave = () => {
    let dumyarray = [...data];
    dumyarray[indexSelect].switchdetails[iSelect].title = newItem2;
    setData(dumyarray);
    setOpen2(false);
  };

  const handleAddItem = () => {
    console.log(selectObject);
    let dumyarray = data;
    const id = selectObject.switchdetails?.length + 1;
    const objectmake = { title: newItem2, id: `s${id}`, onoff: 0 };
    const dumyobject = selectObject;
    dumyobject.switchdetails.push(objectmake);
    console.log(dumyobject);
    const findarray = data.findIndex((p) => p.id === selectObject.id);

    console.log(findarray);
    if (findarray !== -1) {
      dumyarray[findarray] = dumyobject;
    }
    setData(dumyarray);
    setOpen1(false);
  };


  const registerCall = () => {
    console.log("hello baby ", data  );

  }

  // const deviceDetails = [
  //   {
  //     deviceType: "hallroom",
  //     deviceId: "ddssdc",
  //     switchDetails: [
  //       {
  //         id: "s1",
  //         title: "light",
  //         onoff: 0,
  //       },
  //       {
  //         id: "s2",
  //         title: "light",
  //         onoff: 1,
  //       },
  //     ],
  //   },
  //   {
  //     deviceType: "bedroom",
  //     deviceId: "ddssssdc",
  //     switchDetails: [
  //       {
  //         id: "s1",
  //         title: "light",
  //         onoff: 0,
  //       },
  //       {
  //         id: "s2",
  //         title: "light",
  //         onoff: 1,
  //       },
  //     ],
  //   },
  // ];
  useEffect(() => {}, []);

  const allErrorFalse = () => {
    setNameErrorText("");
  };

  const createCategoryValidation = (isUpdate) => {
    if (name.trim() === "") {
      window.scrollTo(0, 0);
      allErrorFalse();
      setNameErrorText("Category name is required");
    } else {
      allErrorFalse();
      // createAndUpdateCategoryApi(isUpdate);
    }
  };

  function createAndUpdateCategoryApi(isUpdate) {
    setDisableBtn(true);
    setCreateBtnLoading(true);

    const endpoint = isUpdate
      ? BaseSetting.endpoint.updateCategory
      : BaseSetting.endpoint.createcategory;

    let data = { name: name, parent_id: parentId?.id };
    if (isUpdate) {
      data.id = editId;
    }
    getApiData(endpoint, "POST", data)
      .then((result) => {
        if (result?.status) {
          toast(result?.message, { type: "success" });
          setIsEditModal(false);
          // getCategoryData(pageNo);
          setName("");
          setParentId(null);
          setCreateCategoryModal(false);
          setDisableBtn(false);
          setCreateBtnLoading(false);
        } else {
          toast(result?.message, { type: "error" });
          setDisableBtn(false);
          setCreateBtnLoading(false);
        }
      })
      .catch((err) => {
        console.log(`💥️ ~ createAndUpdateCategoryApi ~ err ->`, err);
        toast(err?.message, { type: "error" });
        setDisableBtn(false);
        setCreateBtnLoading(false);
      });
  }

  //category list
  function getCategoryData(page, bool = false) {
    if (!bool) {
      setPageLoad(true);
    }
    getApiData(BaseSetting.endpoint.allCategory, "post", {
      page: page,
      download: false,
    })
      .then((result) => {
        if (result?.status) {
          const response = result?.data;
          if (!isEmpty(response)) {
            setCategoryData(response?.categories);
            setPagination(response?.pagination);
          }
          toast(result?.message, { type: "success" });
          setPageLoad(false);
        } else {
          toast(result?.message, { type: "error" });
          setPageLoad(false);
        }
      })
      .catch((err) => {
        toast(err?.message, { type: "error" });
        setPageLoad(false);
      });
  }

  //get one category data for edit
  const openModal = (data) => {
    console.log("data=======>", data);
    setEditId("");
    setName("");
    setParentId({});
    setCreateCategoryModal(true);
    setIsEditModal(true);
    setEditId(data?.id);
    setName(data?.name);
    setParentId(
      parentCategoryData.find((item) => item?.name === data?.category)
    );
    // getParentCategory(data);
  };

  //parent category
  const getParentCategory = (parent) => {
    console.log("parent=======>", parent);
    const endpoint = BaseSetting.endpoint.parentCategory;
    getApiData(endpoint, "post", { type: "admin", parent_id: parent?.id })
      .then((result) => {
        console.log("result", result);
        if (result?.status) {
          const response = result?.data;
          setParentCategoryData(response);
        } else {
          toast(result?.message, { type: "error" });
        }
      })
      .catch((err) => {
        toast(err?.message, { type: "error" });
      });
  };

  // change status
  const changeStatus = (id) => {
    const endpoint = `${BaseSetting.endpoint.changeStatusCategory}`;
    getApiData(endpoint, "post", { categoryId: id })
      .then((result) => {
        if (result?.status) {
          toast(result?.message, { type: "success" });
          // getCategoryData(pageNo, true);
        } else {
          toast(result?.message, { type: "error" });
        }
      })
      .catch((err) => {
        toast(err?.message, { type: "error" });
      });
  };

  const downloadAsPdf = (data) => {
    const pdf = new jsPDF({
      marginLeft: 10,
      marginRight: 10,
      format: "a4",
    });
    const tableData = data;
    const headers = visibleColumns;
    const rows = [];

    const headerStyle = {
      fillColor: BaseColor.primary,
    };

    tableData.forEach((rowData) => {
      const row = [];
      for (const key in rowData) {
        row.push(rowData[key]);
      }
      rows.push(row);
    });
    pdf.setFont("Arial", "normal");
    pdf.setFontSize(12);
    const pageWidth = pdf.internal.pageSize.getWidth() - 20;
    const columnWidth = pageWidth / headers?.length - 1;
    const marginLeft = 13;
    pdf.autoTable({
      head: [headers],
      headerStyle: headerStyle,
      body: rows,
      columnStyles: headers.reduce((acc, header, index) => {
        acc[index] = { cellWidth: columnWidth };
        return acc;
      }, {}),
      margin: { left: marginLeft },
    });
    pdf.save("Category-Data.pdf");
    setVisibleColumns(["id", "name", "category"]);
  };

  const handleDownloadCSV = async (data) => {
    const csvData = Papa.unparse(data);
    const blob = new Blob([csvData], { type: "text/csv" });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.download = "Category.csv";
    link.click();
    URL.revokeObjectURL(url);
  };

  const handleDownloadExcel = async (data) => {
    try {
      const workbook = XLSX.utils.book_new();
      const worksheet = XLSX.utils.json_to_sheet(data);
      XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
      const excelBuffer = XLSX.write(workbook, {
        bookType: "xlsx",
        type: "array",
      });
      const blob = new Blob([excelBuffer], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      saveAs(blob, "Category.xlsx");
    } catch (error) {
      console.error("Error:", error);
    }
  };

  //Download data
  function getDownloadData(type) {
    setPageLoad(true);
    let data = {
      attributes: visibleColumns,
      download: true,
    };
    getApiData(BaseSetting.endpoint.allCategory, "post", data)
      .then((result) => {
        if (result?.status) {
          const response = result?.data?.categories;
          console.log("response========>", response);
          if (type === "Download as PDF") {
            downloadAsPdf(response);
          } else if (type === "Download as CSV") {
            handleDownloadCSV(response);
          } else {
            handleDownloadExcel(response);
          }
          toast(result?.message, { type: "success" });
          setPageLoad(false);
        } else {
          toast(result?.message, { type: "error" });
          setPageLoad(false);
        }
      })
      .catch((err) => {
        toast(err?.message, { type: "error" });
        setPageLoad(false);
      });
  }

  // const categoryColumns = [
  //   {
  //     field: "id",
  //     headerName: "NUMBER",
  //     headerAlign: "center",
  //     align: "center",
  //     flex: 1,
  //     sortable: false,
  //     renderCell: (params) => {
  //       const index =
  //         params.api.getRowIndexRelativeToVisibleRows(params.row.id) + 1;
  //       const mainIndex =
  //         pageNo === 1
  //           ? index
  //           : (pageNo - 1) * pagination?.defaultPageSize + index;
  //       categoryData.map((data) => {
  //         data.serialNumber = mainIndex;
  //       });
  //       return (
  //         <Typography style={{ fontFamily: FontFamily.SemiBold }}>
  //           {params?.row?.serialNumber}
  //         </Typography>
  //       );
  //     },
  //   },
  //   {
  //     field: "name",
  //     headerName: "NAME",
  //     flex: 1,
  //     headerAlign: "center",
  //     align: "center",
  //     flex: 2,
  //   },
  //   {
  //     field: "category",
  //     headerName: "CATEGORY",
  //     flex: 1,
  //     headerAlign: "center",
  //     align: "center",
  //     flex: 2,
  //   },
  //   {
  //     field: "action",
  //     headerName: "ACTIONS",
  //     headerAlign: "center",
  //     flex: 1,
  //     align: "center",
  //     sortable: false,
  //     filterable: false,
  //     renderCell: (params) => {
  //       return (
  //         <Grid container alignItems={"center"} justifyContent={"center"}>
  //           <Grid item>
  //             <Tooltip title="Edit" placement="top" arrow>
  //               <Button
  //                 onClick={() => {
  //                   openModal(params.row);
  //                   console.log(`💥️ ~ Category ~ params.row ->`, params.row);
  //                 }}
  //               >
  //                 <Edit style={{ fontSize: 20 }} />
  //               </Button>
  //             </Tooltip>
  //           </Grid>
  //           <Grid item>
  //             {/* <Tooltip title="Delete" placement="top" arrow>
  //               <Button
  //                 onClick={() => {
  //                   setConfirmationModal(true);
  //                   setDeleteId(params.id);
  //                 }}
  //               >
  //                 <Delete style={{ color: BaseColor.red, fontSize: 20 }} />
  //               </Button>
  //             </Tooltip> */}
  //             <Switch
  //               checked={params?.row?.status === "1" ? true : false}
  //               // onChange={() => changeStatus(params?.row?.id)}
  //             />
  //           </Grid>
  //         </Grid>
  //       );
  //     },
  //   },
  // ];

  const columns = [
    {
      field: 'id',
      headerName: 'NUMBER',
      headerAlign: 'center',
      align: 'center',
      flex: 1,
      sortable: false,
      valueGetter: (params) => {
        const index = params.api.getRowIndex(params.id) + 1;
        return pageNo === 1
          ? index
          : (pageNo - 1) * pagination?.defaultPageSize + index;
      },
      renderCell: (params) => {
        return (
          <Typography style={{ fontFamily: FontFamily.SemiBold }}>
            {params?.value}
          </Typography>
        );
      }
    },
    {
      field: 'name',
      headerName: 'NAME',
      flex: 1,
      headerAlign: 'center',
      align: 'center',
      flex: 2
    },
    {
      field: 'category',
      headerName: 'CATEGORY',
      flex: 1,
      headerAlign: 'center',
      align: 'center',
      flex: 2
    },
    {
      field: 'action',
      headerName: 'ACTIONS',
      headerAlign: 'center',
      flex: 1,
      align: 'center',
      sortable: false,
      filterable: false,
      renderCell: (params) => {
        return (
          <Grid container alignItems={'center'} justifyContent={'center'}>
            <Grid item>
              <Tooltip title='Edit' placement='top' arrow>
                <Button
                  onClick={() => {
                    openModal(params.row);
                    console.log(`💥️ ~ Category ~ params.row ->`, params.row);
                  }}
                >
                  <Edit style={{ fontSize: 20 }} />
                </Button>
              </Tooltip>
            </Grid>
            <Grid item>
              {/* <Tooltip title="Delete" placement="top" arrow>
                  <Button
                    onClick={() => {
                      setConfirmationModal(true);
                      setDeleteId(params.id);
                    }}
                  >
                    <Delete style={{ color: BaseColor.red, fontSize: 20 }} />
                  </Button>
                </Tooltip> */}
              <Switch
                checked={params?.row?.status === '1' ? true : false}
                // onChange={() => changeStatus(params?.row?.id)}
              />
            </Grid>
          </Grid>
        );
      }
    }
  ];

  const rows = [
    { id: 1, name: 'Category 1', category: 'A' },
    { id: 2, name: 'Category 2', category: 'B' },
    { id: 3, name: 'Category 3', category: 'C' }
  ];

  return (
    <Grid container>
      <MiniDrawer
        header="Sign Up"
        children={
          <>
                {/* <DataGrid rows={rows} columns={columns} /> */}

          

          </>
        }
      />
    </Grid>
  );
}
