import * as React from "react";
import { styled, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import CssBaseline from "@mui/material/CssBaseline";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { useLocation, useNavigate } from "react-router-dom";
import Files from "../../Config/Files";
import DraftsIcon from "@mui/icons-material/Drafts";
import GroupIcon from '@mui/icons-material/Group';
import LogoutIcon from "@mui/icons-material/Logout";
import BaseColor from "../../Config/Color";
import authActions from "../../Redux/Reducers/Auth/actions";
import { useDispatch } from "react-redux";
import ConfirmationModal from "../ConfirmationModal";

const drawerWidth = 280;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

export default function MiniDrawer(props) {
  const navigate = useNavigate();
  const { setAccessToken, } = authActions;
  const dispatch = useDispatch();
  const { header, children } = props;
  const theme = useTheme();
  const [open, setOpen] = React.useState(true);
  const [loading, setLoading] = React.useState(false);
  const [modalOpen, setModalOpen] = React.useState(false);
  const location = useLocation();
  const handleCancel = () => {
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  const handleConfirmAction = () => {
    // Your confirm action logic here
    console.log('Action confirmed');
    LogOutUser();
    setModalOpen(false);
  };
  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const routes = {
    // DashBoard: "/dashboard",
  //  "All Users": "/all_users",
  //   "Finclub Users": "/finclub_users",
  // "Free Courses Users": "/courses_users ",
  //   "Quiz Users": "/quiz_user",
  //   "NewLetter": "/newletter",
  //   "Brokerage Users": "/broker_user",
  //   "News": "/news",
    "Webinar": "/webinar", 
    "PlatForm User": "/platFormUser",
  };

  const pathname = location?.pathname;

  const LogOutUser = () => {
    localStorage.clear();
    dispatch(setAccessToken(''));
    window.location.reload();
    // navigate("/login")
  };

  return (
    <Box sx={{ display: "flex", width: "100%" }}>
      <CssBaseline />
      <AppBar position="fixed" open={open}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{
              marginRight: 5,
              ...(open && { display: "none" }),
            }}
          >
            <MenuIcon />
          </IconButton>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              width: "100%",
            }}
          >
            <Typography variant="h6" noWrap component="div">
              {header}
            </Typography>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                cursor: "pointer",
              }}
              // onClick={() => LogOutUser()}
              onClick={() => setModalOpen(true)}
            >
              <Typography variant="h6" noWrap component="div">
                Log Out
              </Typography>
              <LogoutIcon style={{ marginLeft: "5px" }} />
            </div>
          </div>
        </Toolbar>
      </AppBar>
      <Drawer variant="permanent" open={open}>
        <DrawerHeader>
          <img
            src={Files?.images?.logo}
            alt="Scarp C"
            style={{ height: 60, width: 200, objectFit: "contain" }}
          />
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === "rtl" ? (
              <ChevronRightIcon />
            ) : (
              <ChevronLeftIcon />
            )}
          </IconButton>
        </DrawerHeader>
        <Divider />
       
        {Object.keys(routes).map((routeName, index) => {
  const route = routes[routeName];

  return (
    <ListItem
    selected={route === pathname}
    button
    key={route}
    disablePadding
    sx={{
      display: "block",
      "&:hover": { backgroundColor: BaseColor.disablePrimary },
      ...(route === pathname && {
        backgroundColor: BaseColor.disablePrimary, // Apply background color for selected item
        "& .MuiListItemIcon-root, & .MuiListItemText-primary": {
          color: BaseColor.primary, // Apply font color for selected item
        }
      })
    }}
  >
    <ListItemButton
      onClick={() =>
        // index === 0
        //   ? navigate("/dashboard")
        //   : 
        //   index === 0
        //   ? navigate("/all_users")
        //   : index === 1
        //   ? navigate("/finclub_users")
        //   : index === 2
        //   ? navigate("/courses_users")
        //   : index === 3
        //   ? navigate("/quiz_user")
        //   : index === 4
        //   ? navigate("/newletter")
        // : 
        // index === 5
        //   ? navigate("/broker_user")  
        //   : index === 6
        //   ? navigate("/news")  
        //   : 
          index === 0
          ?
           navigate("/webinar")  
          : index === 1
           ?
            navigate("/platFormUser")  
          : ""
      }
      sx={{
        minHeight: 48,
        justifyContent: open ? "initial" : "center",
        px: 2.5,
        fontWeight: 'bold', // Default font weight
        "&:hover": {
          color: BaseColor.primary, // Change font color on hover
          fontWeight: 'bold', // Increase font weight on hover
          "& .MuiSvgIcon-root": {
            color: BaseColor.primary, // Change icon color on hover
          }
        }
      }}
    >
      <ListItemIcon
        sx={{
          minWidth: 0,
          mr: open ? 3 : "auto",
          justifyContent: "center",
        }}
      >
        {/* {index === 0 ? (
          <CategoryIcon />
        ) : */}
         {
        //  index === 0 ? (
        //   <GroupIcon />
        // ) : index === 1 ? (
        //   <GroupIcon />
        // ) : index === 2 ? (
        //   <GroupIcon />
        // ) : index === 3 ? (
        //   <GroupIcon />
        // ) : index === 4 ? (
        //   <DraftsIcon />
        // ) :
        // index === 5 ? (
        //   <DraftsIcon />
        // ) :
        // index === 6? (
        //   <DraftsIcon />
        // ):
        index === 0? (
          <DraftsIcon />
        ):
        index === 1? (
          <GroupIcon />
        ):

          ""
        }
      </ListItemIcon>
      <ListItemText
        primary={routeName}
        sx={{ opacity: open ? 1 : 0 }}
      />
    </ListItemButton>
  </ListItem>
  
  );
})}

      </Drawer>
      <Box component="main" sx={{ flexGrow: 2, p: 3, width: "80%" }}>
        <DrawerHeader />
        {children}
      </Box>
      <ConfirmationModal
        open={modalOpen}
        handleClose={handleCloseModal}
        handleConfirm={handleConfirmAction}
      />
    </Box>
  );
}
