const actions = {
  SET_ACCESS_TOKEN: "auth/SET_ACCESS_TOKEN",
  SET_USER_DATA: "auth/SET_USER_DATA",

  setAccessToken: (token) => ({
      type: actions.SET_ACCESS_TOKEN,
      accessToken: token,
    }),
  setUserData: (data) => (dispatch) =>
    dispatch({
      type: actions.SET_USER_DATA,
      userData: data,
    }),
};
export default actions;
