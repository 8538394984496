import React from "react";
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button } from "@mui/material";
import BorderButton from "../BorderButton";
import CButton from "../CButton";

const ConfirmationModal = ({ open, handleClose, handleConfirm }) => {
  return (
    <Dialog open={open} onClose={handleClose} 
    PaperProps={{
      style: {
        minWidth: '500px', // Increase this value as needed
      },
    }}
    
    >
      <DialogTitle>{"Confirm Action"}</DialogTitle>
      <DialogContent>
        <DialogContentText style={{fontWeight:'500'}}>
          Are you sure you want to LogOut ?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <div style={{display:'flex', flexDirection:'row' ,justifyContent:'flex-end'}}>
        <BorderButton onClick={handleClose} color="primary" style={{marginTop:'10px'}}>
         CANCEL
        </BorderButton>
        <CButton onClick={handleConfirm} color="primary" autoFocus 
        style={{marginTop:'10px'}}>
         CONFIRM
        </CButton>
        </div>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmationModal;
